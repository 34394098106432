import React from 'react';

interface OwnProps {
  duration: number;
}

type Props = OwnProps;

const DisplayDuration: React.FunctionComponent<Props> = ({ duration }) => {
  const hours = duration / 60 / 60;
  const numberFormat = new Intl.NumberFormat('sk-SK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return <span>{numberFormat.format(hours)} hod.</span>;
};

DisplayDuration.displayName = 'DisplayDuration';

export default DisplayDuration;
